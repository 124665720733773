<template>
  <div  class="minWidth1000">
    <custom-actions :panel="false">
      <template #header>
        <el-button v-if="permission.add" type="primary" icon="el-icon-plus" @click="showForm(null)">新增基站</el-button>
      </template>
    </custom-actions>

    <el-card shadow="never" class="mt10">
      <el-table stripe size="mini" default-expand-all border v-loading="loading" :data="tableData" row-key="deptId" :tree-props="{children: 'childNode'}">
        <el-table-column label="基站名称" prop="deptName"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <el-button v-if="permission.edit" type="text" @click="showForm(row)">编辑</el-button>
            <el-button type="text" v-if="row.parentDeptId !== '1' && permission.del" @click="deleteDept(row.deptId)" class="del-btn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :title="form.deptId ? '编辑基站' : '新增基站'" :close-on-click-modal="false" :visible.sync="dialogVisible" width="400px">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="基站名称" prop="deptName">
          <el-input v-model="form.deptName" placeholder="请输入基站名称"/>
        </el-form-item>
        <el-form-item label="站址编码" prop="deptCode">
          <el-input v-model="form.deptCode" placeholder="请输入站址编码"/>
        </el-form-item>
        <el-form-item label="基站类型" prop="deptType">
          <el-select v-model="form.deptType" placeholder="请选择基站类型">
           <el-option
            v-for="item in dictTypeList"
           :key="item.dicId"
           :label="item.dicValue"
           :value="item.dicCode"/>
          </el-select>

        </el-form-item>
        <el-form-item label="上级基站" prop="parentDeptId">
          <el-cascader
            v-model="form.parentDeptId"
            :show-all-levels="false"
            :options="tableData"
            :props="{ emitPath: false, label: 'deptName', value: 'deptId', children: 'childNode', checkStrictly: true }"
            @change="deptChange">
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'OrganizeManage',
  data () {
    return {
      tableData: [],
      dictTypeList: [],
      loading: false,
      form: {
        deptName: null,
        parentDeptId: ''
      },
      rules: {
        deptName: [{ required: true, message: '请输入基站名称' }],
        parentDeptId: [{ required: true, message: '请选择上级基站' }]
      },
      dialogVisible: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.form.deptId ? this.$apis.system.updateSysDept : this.$apis.system.insertSysDept
          this.$axios.post(url, this.form).then(() => {
            this.dialogVisible = false
            this.loadData()
          })
        }
      })
    },
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        this.tableData = res
      }).finally(() => {
        this.loading = false
      })
    },
    deptChange (value) {
      if (this.form.deptId === value) {
        this.$message.error('不能选择自身作为上级')
        this.form.parentDeptId = ''
      }
    },
    loadDetails (deptId) {
      this.$axios.get(this.$apis.system.selectSysDeptByDeptId, {
        params: { deptId }
      }).then(res => {
        this.form = res
      })
    },
    loadDictTypeList () {
      this.$axios.get(this.$apis.system.selectDicByList, {
        params: { dicType: 'deptType' }
      }).then(res => {
        this.dictTypeList = res.deptType
      })
    },
    showForm (row) {
      this.dialogVisible = true
      this.loadDictTypeList()
      this.$nextTick(() => {
        if (row) {
          this.loadDetails(row.deptId)
          this.$refs.form.clearValidate()
        } else {
          this.form = {
            deptName: null,
            parentDeptId: null,
            corpId: null
          }
          this.$refs.form.resetFields()
        }
      })
    },
    deleteDept (deptId) {
      this.$confirm('确认删除该材料, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.system.deleteSysDept, {
          deptId
        }).then(() => {
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%;
}
</style>
